.profile-header {
  border-bottom: 3px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-style: solid;
  border-color: black;
  
  padding-bottom: 5px;
  background-color: #17A2B8;
  max-height: 25vh;
  min-height: 250px;
}

.multi-select {
  color: black;
  max-width: 300px;
}

.header-min-width {
  min-width: 1450px;
}


.header-info-column-width-450-right {
  max-width: 450px;
  margin-left: auto;
  margin-right: 15px;
  display: inline-block;
  text-align: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.header-info-column-width-250 {
  max-width: 250px;
  min-width: 250px;
}

.header-info-column-width-300 {
  max-width: 300px;
  min-width: 300px;
}

.header-info-column-width-350 {
  max-width: 350px;
  min-width: 350px;
}

.header-info-column-width-400 {
  max-width: 400px;
  min-width: 400px;
}

.header-info-column-width-450 {
  max-width: 450px;
  min-width: 450px;
}

.input-form-column {
  margin: 5px;
}

.input-form-column-one {
  align-content: center;
  text-align: center;
}

.input-form-column-item {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: right;
}

.for-sale-download-csv {
  margin-top: 5px;
}