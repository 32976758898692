.Leaderboard-FilterBox-Max-Width {
  max-width: 200px;
  margin-left: 20px;
}

.Leaderboard-Filter-col {
  display:flex;
  padding-bottom: 7px;
  margin: 2px;
  align-content: center;
}