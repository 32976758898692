.form-aligns {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.property-table-min-width {
  min-width: 1100px;
}

.page-selector-class {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #343A40;
  text-align: center;
  border-style: solid;
  border-radius: 10px;
  border-color: #343A40;
  margin-bottom: 5px;
  margin-right: 10px;
}

.page-selector-left {
  width: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px 0px 0px 12px;
}

.page-selector-right {
  width: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0px 12px 0px 0px;
}

.page-selector-center-left {
  width: 40px;
}

.page-selector-center-right {
  width: 110px;
}

.page-selector-center {
  border-style: solid;
  border-top: 3px;
  border-bottom: 3px;
  border-color: #343A40;
  padding-top: 10px;
  padding-bottom: 10px;
}

.sort-selector-padding {
  padding: 0px 5px 0px 5px;
}

.filter-col {
  display:flex;
  padding-bottom: 7px;
  margin: 2px;
}