
.collection-wrapper {
  display: inline-block;
  position: relative;
}

.info-box-display {
  position: absolute;
  bottom: 22px;
  min-width: 300px;
  padding: 0px 5px 0px 5px;
  z-index: 1;
  border-radius: 10px;
  border: 1px solid black;
  margin: 1px;
}

.info-box-display-left {
  left: -300px;
}

.info-box-display-right {
  left: 300px;
}

.info-box-no-display {
  display: none;
}

.collection-square {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 1px;
}