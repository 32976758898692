.login-image {
  max-width: 400px;
}

.button-placing {
  padding: 5px;
  width: 40%;
  min-width: 285px;
  margin: auto;
}

.login-button-width {
  width: 135px;
  margin-top: 5px;
}

.about-min-column-width {
  min-width: 100px;
}

.center-list {
  text-align: center;
}

.login-page-height {
  padding-top: 70px;
}

.login-green-box-class {
  margin: 10px 0px 10px 0px;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
}

.register-margin {
  margin: 10px 0px 10px 0px;
}