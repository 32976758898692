.error-center {
  text-align:center;
}

.error-icon-height {
  text-align:center;
  height: 250px;
  color: #DC3545;
}

.error-full-style {
  margin-top: 60px;
}