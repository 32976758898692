html, body, #root {
  position: absolute;
  height: 100vh;
  width: 99vw;
  margin: 0px;
  padding: 0px;
  top: 0px;
  right: 0px;
  background-color: #6C757D;
  overflow-y: auto;
  overflow-x: auto;
}

.top-level-container {
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
  margin: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  padding: 0px;
}
