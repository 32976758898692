.toggle-on {
  background-color: #28A745;
  width: 32%;
  height: 20px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 1px;
  text-align: center;
  padding-bottom: 25px;
  margin-top: 10px;
}

.toggle-off {
  background-color: #DC3545;
  width: 32%;
  height: 20px;
  border-radius: 10px;
  border: 1px solid black;
  margin: 1px;
  text-align: center;
  padding-bottom: 25px;
  margin-top: 10px;
}

.toggle-row {
  margin-left: auto;
  margin-right: auto;
}

.sale-history-header-min-width {
  min-width: 1400px;
}

.sale-history-date-pad-left {
  display: inline;
  background-color: white;
  float: left;
}

.sale-history-date-pad-right {
  display: inline;
  background-color: white;
  float: right;
}